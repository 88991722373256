import { graphql } from "gatsby";
import * as React from "react";
import Clients from "../components/Clients";
import Layout from "../components/layout";
import theme from "../styles/theme";

const ClientsPage = ({ location, data }) => {
  const seo = {
    tags: [
      {
        tagName: "title",
        content: "References",
      },
    ],
  };
  return (
    <Layout location={location} seo={seo} logo={data.global.logo}>
      <main
        css={`
          margin: 0 auto;
          padding-bottom: 200px;
          width: 100%;
          max-width: 1600px;
          box-sizing: border-box;
          padding-top: 200px;
          h2 {
            display: none;
          }
          ${theme.tablet} {
            padding-top: 140px;
          }
        `}
      >
        <Clients dataTitle=" " dataClients={data.clients} mt="0" />
      </main>
    </Layout>
  );
};

export default ClientsPage;

export const query = graphql`
  query ClientsQuery {
    clients: allDatoCmsClient(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          title
          text
          slug
          thumbnail {
            fluid(maxWidth: 512) {
              ...GatsbyDatoCmsFluid
            }
            alt
          }
        }
      }
    }
    global: datoCmsGlobal {
      logo {
        fluid(maxWidth: 700) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
    }
  }
`;
